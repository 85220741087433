.toolbar {
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: white;
    height: 120px;
    /* border-bottom: 0.8px solid #333333; */
    box-shadow: 0px 0px 2.5px #14d109;
    z-index: 101;
}

.toolbar-nav {
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
    font-size: 1.45rem;
}

.toolbar-nav a {
    margin-right: 5px;
    text-decoration: none;
    cursor: pointer;
    color: black;
    margin-left: 7.5px;
}

.nav-link:hover {
    color: #14d109;
}

.toolbar-logo-icon {
    width: 236px;
    height: 114.133px;
}

.nav-link {
    align-self: flex-end;
    margin-bottom: 15px;
}

@media (min-width: 1200px) {
    .toolbar-nav a {
        font-size: larger;
    }
}

@media (max-width: 1090px) {
    .nav-link {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
}

@media(max-width: 432px) {
    .toolbar {
        display: none;
    }
}