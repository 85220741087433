.mobile-menu {
    box-shadow: 0px 0px 2.5px #14d109;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.mobile-menu-logo-icon {
    width: 50px;
    height: 50px;
}

#mobile-menu-logo-link {
    margin-left: 10px;
}

.mobile-menu .nav-link {
    margin-bottom: 5px;
    margin-right: 10px;
}

.mobile-menu a {
    text-decoration: none;
    color: black;
}

.mobile-menu a:hover {
    color: #14d109;
}

@media(min-width: 432px) {
    .mobile-menu {
        display: none;
    }
}

@media(max-width: 365px) {
    .mobile-menu a {
        font-size: 11pt;
    }
}

@media(max-width: 345px) {
    .mobile-menu a {
        font-size: 10pt;
    }
}

@media(max-width: 325px) {
    .mobile-menu a {
        font-size: 9pt;
    }
}