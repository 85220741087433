.home-container {
}

.home-header {
    margin-bottom: 5px;
}

.home-subheader {
    margin-top: 0px;
}

#mission-explanation {
    margin-right: 25px;
}

.home-why a {
    text-decoration: none;
    font-weight: bold;
    font-style: italic;
    color: black;
}

.whats-in-your-trash-photo {
    box-shadow: 0px 0px 8px #82d02b;
}

.home-right-side {
    margin-left: 50px;
    padding: 10px;
    width: 80%;
}

.mission-pics {
    padding: 25px;
    justify-content: space-around;
    /* background-color: #dddddd; */
    border-radius: 15px;
    display: flex;
    flex-direction: row;
}

.mission-pic {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    align-items: center;
    text-shadow: 0px 0px 1px #82d02b;
}

.mission-pic img {
    margin-top: 15px;
    box-shadow: 0px 0px 4.5px black;
    width: 288px;
    height: 191.7px;
    margin-bottom: 15px;
}

@media(max-width: 540px) {
    .home-right-side {
        margin-left: 0px;
    }
    .home-container {
        flex-direction: column-reverse;
    }

    .mission-pics {
        flex-direction: column;
    }

    .whats-in-your-trash-photo {
        width: 360px;
    }
}