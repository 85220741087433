.screen-content {
    margin-right: 25px;
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.screen-header {
    align-self: center;
    flex-direction: column;
    display: flex;
}

.subheader {
    color: #82d02b;
    align-self: center;
}

.box {
    background-color: #82d02b;
    border-radius: 50px;
    padding: 25px;
    box-shadow: 0px 0px 3px black;
    align-self: center;
}

.row {
    display: flex;
    flex-direction: row;
}

@media(min-width: 432px) {
    .screen-content {
        margin-top: 130px;
    }
}