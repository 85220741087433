.where-things-go-content {
    display: flex;
    flex-direction: column;
}

.drop-off-map-container {
    align-self: center;
}

.where-things-go-content span {
    width: 95%;
    align-self: center;
    margin-bottom: 25px;
    font-size: larger;
}